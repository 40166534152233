/* eslint-disable no-nested-ternary */
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';

import { Logo } from '~/components/modules/Logo/Logo';
import { OnboardingTourTargetContainer } from '~/components/modules/OnboardingTour/components/OnboardingTourTargetContainer';
import { DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR } from '~/components/modules/OnboardingTour/Dashboard/DashboardOnboardingTour';
import { TrackEvent } from '~/components/modules/Tracking/TrackEvent';
import { AddFundsInitiators, AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { ManagedPortfolioContext } from '~/context/ManagedPortfolioContext';
import { TradingPortfolioContext } from '~/context/TradingPortfolioContext';
import { DepositTransferModalKey, ReferralGlobal, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
import { useAuthentication } from '~/hooks/useAuthentication';
import { useCustomPageTitle } from '~/hooks/useCustomPageTitle';
import { useWindowSize } from '~/hooks/useWindowSize';
import { PortfolioTypes } from '~/models/PortfolioType';
import { baseRoutes } from '~/routes/applicationRoutes';
import { splitPathArray } from '~/utils/routesHelper';
import { Box, Flex, Placeholder, Text } from '~/vinovest-design-system/components';
import { Button } from '~/vinovest-design-system/components/Button';
import { IconClose, IconHamburger } from '~/vinovest-design-system/components/Icons';
import { MenuLink, MenuLinks } from '~/vinovest-design-system/components/Navigation';

import { GlobalNavigation } from './GlobalNavigation';
import { NAVIGATION_DASHBOARD_LINKS } from './Navigation.data';
import { ReferralGlobalModal } from '../Modal/ReferralGlobalModal';
import { GlobalSubNavigation } from './GlobalSubNavigation';

/** Mobile to desktop responsive navigation for authenticated users. */
export const ApplicationNavigation = () => {
    const { isDesktop, isMobile } = useWindowSize();
    const router = useRouter();
    const paths = splitPathArray(router?.asPath, router?.pathname);
    const { pageTitle } = useCustomPageTitle();
    const { firstName } = useAuthentication();
    const { setDestinationPortfolio, setDepositAmount } = useContext(DepositContext);
    const [toggleMenu, setToggleMenu] = useState(false);
    const [pageTitleDisplay, setPageTitleDisplay] = useState('Home');
    const dashboard = useMemo(() => {
        const paths = [baseRoutes.dashboard.href];
        const pathsRegex = new RegExp(`(${paths.join('|')})`);
        return pathsRegex.test(router.pathname);
        // router.pathname.includes(), [router.pathname]
    }, [router.pathname]);
    const trading = useMemo(() => router.pathname.includes(baseRoutes.trade.href), [router.pathname]);
    const whiskey = useMemo(() => router.pathname.includes(baseRoutes.whiskey.href), [router.pathname]);
    const wineOffer = useMemo(() => router.pathname.startsWith('/managed/offer/'), [router.pathname]);
    const { data: managedPortfolio, isSuccess } = useManagedPortfolioQuery();
    const [isManaged, setIsManaged] = useState(false);
    const minWineDeposit = useMemo(() => {
        if (isSuccess && managedPortfolio && managedPortfolio.cash && managedPortfolio.positionTotal) {
            const cashAmount = typeof managedPortfolio.cash.amount === 'number' ? managedPortfolio.cash.amount : 0;
            const positionTotalAmount =
                typeof managedPortfolio.positionTotal.amount === 'number' ? managedPortfolio.positionTotal.amount : 0;
            return cashAmount + positionTotalAmount <= 100_000;
        }
        return false;
    }, [isSuccess, managedPortfolio]);

    useEffect(() => {
        if (router.pathname.startsWith('/manage')) {
            return setPageTitleDisplay('Wine');
        }
        if (router.pathname.startsWith('/whiskey')) {
            return setPageTitleDisplay('Whiskey');
        }
        if (router.pathname.startsWith('/trade')) {
            return setPageTitleDisplay('Trading');
        }
        if (router.pathname.startsWith('/account')) {
            return setPageTitleDisplay('Settings');
        }
        return setPageTitleDisplay('Home');
    }, [router]);

    const portfolio = useMemo(() => {
        if (trading) {
            return PortfolioTypes.trading;
        }
        if (whiskey) {
            return PortfolioTypes.whiskey;
        }
        setIsManaged(true);
        return PortfolioTypes.managed;
    }, []);

    // deposit pages have no header 'add funds'
    const deposit = useMemo(() => router.pathname.startsWith(baseRoutes.upsell.href), [router.pathname]);

    const managedPortfolioContext = useContext(ManagedPortfolioContext);
    const tradingPortfolioContext = useContext(TradingPortfolioContext);
    const needsOnboardingTour = managedPortfolioContext?.needsOnboarding || tradingPortfolioContext?.needsOnboarding;
    const menuLinkData = NAVIGATION_DASHBOARD_LINKS;

    const handleAddFunds = () => {
        switch (portfolio) {
            case PortfolioTypes.managed: {
                setIsManaged(true);
                setDestinationPortfolio(PortfolioTypes.managed);
                setDepositAmount(0);
                break;
            }
            case PortfolioTypes.whiskey: {
                setIsManaged(false);
                setDestinationPortfolio(PortfolioTypes.whiskey);
                setDepositAmount(0);
                break;
            }
            case PortfolioTypes.trading: {
                setIsManaged(false);
                setDestinationPortfolio(PortfolioTypes.trading);
                setDepositAmount(0);
                break;
            }
            default: {
                // Assumes default type is PortfolioTypes.managed
                setDestinationPortfolio(PortfolioTypes.managed);
                setDepositAmount(0);
                break;
            }
        }

        UrlModalHelper.openModal(
            router,
            DepositTransferModalKey,
            wineOffer ? baseRoutes.managed.href : minWineDeposit && isManaged ? '/managed/offer/wine-offer' : ''
        );
    };

    const AddFundsButton = () => {
        if (deposit) {
            return null;
        }
        return (
            <TrackEvent eventName={AnalyticsEventNames.AddFunds} metaData={{ initiator: AddFundsInitiators.Header }}>
                <Button
                    variant={'custom'}
                    className={
                        'text-14 rounded bg-green-600 text-white p-3 capitalize hover:bg-opacity-90 whitespace-nowrap'
                    }
                    onClick={handleAddFunds}
                >
                    Add Funds
                </Button>
            </TrackEvent>
        );
    };

    const logoType = 'standard';

    return (
        <>
            <a href={'#main-content'} className={'skip-nav'}>
                Skip to main content
            </a>
            <Disclosure
                as={'nav'}
                id={'Header'}
                key={'ApplicationNavigation'}
                style={{ position: '-webkit-sticky', top: 0, zIndex: 20 }}
                className={`print:hidden bg-white sticky flex flex-col md:px-0 shadow-sm md:shadow-none`}
            >
                {({ open }) => (
                    <div className={'flex min-h-[75px] items-center'}>
                        {isDesktop && (
                            <GlobalNavigation dashboard={dashboard} needsOnboardingTour={needsOnboardingTour} />
                        )}
                        {isMobile && (
                            <Flex classnames={'w-full flex-grow bg-[#ffffff] flex-col'}>
                                <Flex classnames={'w-full justify-between'}>
                                    <Flex classnames={'justify-start items-center w-[33%]'}>
                                        <Disclosure.Button
                                            className={
                                                'inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-neutrals-800'
                                            }
                                        >
                                            <span className={'sr-only'}>Open main menu</span>
                                            <IconHamburger height={'18px'} width={'22px'} stroke={'#242E35'} />
                                        </Disclosure.Button>
                                        <Placeholder renderCondition={paths[0]?.href !== ''} placeholder={null}>
                                            <Flex classnames={'items-center'}>
                                                <Text
                                                    classnames={`md:border-l md:border-black ml-2 md:ml-8 md:pl-8 capitalize whitespace-nowrap max-w-[100px] w-[180px] sm:w-[auto] truncate`}
                                                >
                                                    {pageTitleDisplay}
                                                </Text>
                                            </Flex>
                                        </Placeholder>
                                    </Flex>
                                    <Flex classnames={'flex-1 items-center justify-center w-[33%]'}>
                                        <Box classnames={`block w-[75px]`}>
                                            <Logo type={logoType} />
                                        </Box>
                                    </Flex>
                                    <Flex classnames={'flex-1 justify-end items-center w-[33%] pr-2'}>
                                        <Box>
                                            <OnboardingTourTargetContainer
                                                needsOnboarding={needsOnboardingTour}
                                                stepNumber={0}
                                                baseTargetSelector={DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR}
                                                containerClassNames={'w-min'}
                                                maximumAllowedScreenSize={'tablet'}
                                            >
                                                <AddFundsButton />
                                            </OnboardingTourTargetContainer>
                                        </Box>
                                    </Flex>
                                </Flex>
                                <GlobalSubNavigation />
                            </Flex>
                        )}
                        {/* Mobile Menu */}
                        <Transition.Root key={'MobileMenuTransitionRoot'} show={open} as={Fragment}>
                            <Dialog
                                as={'div'}
                                className={'fixed inset-0 overflow-hidden z-20'}
                                onClose={() => setToggleMenu(!toggleMenu)}
                            >
                                <Disclosure.Button
                                    className={
                                        'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-full h-full'
                                    }
                                />

                                <div className={'pointer-events-none fixed inset-y-0 right-0 flex pl-10 max-w-[95%]'}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter={'transform transition ease-in-out duration-500 sm:duration-700'}
                                        enterFrom={'translate-x-full'}
                                        enterTo={'translate-x-0'}
                                        leave={'transform transition ease-in-out duration-500 sm:duration-700'}
                                        leaveFrom={'translate-x-0'}
                                        leaveTo={'translate-x-full'}
                                    >
                                        <div className={'pointer-events-auto w-screen max-w-md'}>
                                            <div
                                                className={
                                                    'flex h-full flex-col overflow-y-scroll bg-white shadow-xl relative'
                                                }
                                            >
                                                <div className={'relative pt-2 flex flex-col text-20 px-6'}>
                                                    <div className={'block absolute top-5 right-5'}>
                                                        <Disclosure.Button
                                                            className={
                                                                'w-8 h-8 bg-neutrals-200 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 p-3'
                                                            }
                                                        >
                                                            <span className={'sr-only'}>Close main menu</span>
                                                            <IconClose
                                                                height={'12px'}
                                                                width={'12px'}
                                                                stroke={'#242E35'}
                                                                className={'ml-[-2px] mt-[-2px]'}
                                                            />
                                                        </Disclosure.Button>
                                                    </div>

                                                    {dashboard && firstName ? (
                                                        <Text
                                                            classnames={'capitalize mt-5 mb-8 text-16'}
                                                            color={'text-gray-500'}
                                                        >
                                                            Welcome, {firstName}!
                                                        </Text>
                                                    ) : (
                                                        <Placeholder
                                                            renderCondition={pageTitle !== ''}
                                                            placeholder={null}
                                                        >
                                                            <Text classnames={'text-16 my-4 text-gray-500 capitalize'}>
                                                                {pageTitleDisplay}
                                                            </Text>
                                                        </Placeholder>
                                                    )}

                                                    <MenuLinks classnames={'flex-col w-full pt-3'}>
                                                        {menuLinkData.map((link) =>
                                                            link.label === 'Invite' ? (
                                                                <MenuLink
                                                                    key={link.label}
                                                                    classnames={'text-20 py-5'}
                                                                    onClick={() => {
                                                                        UrlModalHelper.openModal(
                                                                            router,
                                                                            ReferralGlobal
                                                                        );
                                                                    }}
                                                                >
                                                                    {link.label}
                                                                </MenuLink>
                                                            ) : (
                                                                <MenuLink
                                                                    key={link.label}
                                                                    classnames={'text-20 py-5 capitalize'}
                                                                    link={link.to}
                                                                >
                                                                    {link.label}
                                                                </MenuLink>
                                                            )
                                                        )}
                                                    </MenuLinks>
                                                </div>
                                                <div className={'px-6'}>
                                                    <MenuLinks classnames={'flex-col w-full'}>
                                                        <MenuLink
                                                            link={baseRoutes.account.href}
                                                            classnames={'text-20 py-5 capitalize'}
                                                            variant={'custom'}
                                                        >
                                                            Settings
                                                        </MenuLink>
                                                        <MenuLink
                                                            link={''}
                                                            classnames={'text-20 py-5 capitalize'}
                                                            variant={'custom'}
                                                            onClick={() => {
                                                                setToggleMenu(!toggleMenu);
                                                                UrlModalHelper.openModal(router, ReferralGlobal);
                                                            }}
                                                        >
                                                            <Disclosure.Button>Invite</Disclosure.Button>
                                                        </MenuLink>
                                                    </MenuLinks>
                                                </div>
                                                <div className={'px-6 absolute bottom-[20px] w-full'}>
                                                    <Button
                                                        onClick={handleAddFunds}
                                                        variant={'green'}
                                                        classnames={'w-full capitalize text-16'}
                                                    >
                                                        Add Funds
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>
                        <ReferralGlobalModal />
                    </div>
                )}
            </Disclosure>
        </>
    );
};
